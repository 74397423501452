<template>
  <section class="bg-light h-100 p-3">
    <h2>Accompagnement</h2>
    <hr />
    <article class="mt-3">
      <div
        class="d-flex flex-wrap align-items-stretch justify-content-start row-cols-4"
      >
        <div
          class="col p-3 cursor-pointer"
          v-for="(item, i) in services"
          :key="i"
          @click="chooseUpdate(item)"
          :class="{
            'border border-primary border-2':
              item.id_service == form.id_service,
          }"
        >
          <div class="card shadow bg-dark text-light border-0 h-100">
            <img
              :src="URL_IMAGE + item.logo_service"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-primary">
                {{ item.title_service }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </article>
    <div class="mt-4">
      <h2>Modification</h2>
      <hr />
      <form class="row g-3 mb-4" @submit.prevent="confirm">
        <div class="col-12">
          <label for="inputMail1" class="form-label">Titre</label>
          <input
            type="text"
            class="form-control"
            v-model="form.title_service"
          />
          <!-- <div
            v-if="submitted && $v.form.email_faq.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.email_faq.required"
              >Veuillez insérer votre adresse mail</span
            >
            <span v-if="!$v.form.email_faq.email"
              >Veuillez insérer une adresse mail valide</span
            >
          </div> -->
        </div>
        <div class="col-12">
          <label for="inputMail1" class="form-label">Logo</label>
          <input
            id="file-input"
            type="file"
            accept="image/svg+xml "
            class="form-control"
            @change="changeFile"
          />
          <!-- <div
            v-if="submitted && $v.form.email_faq.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.email_faq.required"
              >Veuillez insérer votre adresse mail</span
            >
            <span v-if="!$v.form.email_faq.email"
              >Veuillez insérer une adresse mail valide</span
            >
          </div> -->
        </div>
        <div class="col-12">
          <label for="inputMessage" class="form-label">Content</label>
          <div class="vueEditor">
            <vue-editor
              :customModules="customModulesForEditor"
              :editor-options="editorSettings"
              required
              v-model="form.content_service"
            ></vue-editor>
          </div>
          <!-- <div
            v-if="submitted && !$v.form.message_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre message ici
          </div> -->
        </div>

        <div class="col-12 mb-2 mt-4 d-flex justify-content-between">
          <!-- <button
            v-if="loading.send"
            class="btn btn-primary"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button> -->
          <button v-if="loading" class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button>
          <button v-else type="submit" class="btn btn-primary">Modifer</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import {
  getAllServices,
  updateServices,
  updateServicesIcon,
} from "../../api/serviceAdmin";
import { URL_IMAGE } from "../../configs/index";
import { success, error } from "../../utils/toast";
import { VueEditor } from "vue2-editor";
import BlotFormatter from "quill-blot-formatter";

export default {
  name: "Accompagnement",
  components: { VueEditor },
  data() {
    return {
      loading: false,
      URL_IMAGE: URL_IMAGE,
      services: [],
      customModulesForEditor: [
        { alias: "BlotFormatter", module: BlotFormatter },
      ],
      editorSettings: {
        modules: {
          BlotFormatter: true,
        },
      },
      form: {
        id_service: 0,
        title_service: "",
        content_service: "",
      },
      formLogo: {
        image: null,
      },
    };
  },
  mounted() {
    getAllServices().then((result) => {
      this.services = result.data;
    });
  },
  methods: {
    chooseUpdate(data) {
      this.form = {
        id_service: data.id_service,
        content_service: data.content_service,
        title_service: data.title_service,
      };
      this.formLogo = {
        image: null,
      };
    },
    changeFile(event) {
      this.formLogo.image = event.target.files[0];
    },
    confirm() {
      if (this.form.id_service != 0) {
        this.loading = true;
        updateServices(this.form.id_service, this.form).then((result) => {
          if (result.data.error) {
            error(result.data.error);
          } else {
            if (this.formLogo.image) {
              updateServicesIcon(this.form.id_service, this.formLogo).then(
                (result) => {
                  if (result.data.error) {
                    error(result.data.error);
                  } else {
                    getAllServices().then((result) => {
                      this.services = result.data;
                    });
                    success("Modification réussi");
                    this.loading = false;
                  }
                }
              );
            } else {
              getAllServices().then((result) => {
                this.services = result.data;
              });
              success("Modification réussi");
              this.loading = false;
            }
          }
        });
      } else {
        error("Choisissez une accompagnement");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
